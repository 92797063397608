define([
    'lodash',
    'componentsCore',
    'santa-components',
    'santa-core-utils',
    'tpaComponents/mixins/tpaCompBaseMixin',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/mixins/tpaSectionMixin',
    'tpaComponents/mixins/tpaResizeWindowMixin'
], function (
    _,
    componentsCore,
    santaComponents,
    coreUtilsLib,
    tpaCompBaseMixin,
    tpaUrlBuilderMixin,
    tpaCompApiMixin,
    tpaSectionMixin,
    tpaResizeWindowMixin
) {
    'use strict';

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPASection
     * @extends {tpa.mixins.tpaCompBase}
     * @extends {tpa.mixins.tpaUrlBuilder}
     * @extends {tpa.mixins.tpaCompAPI}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const TPASection = {
        displayName: 'TPASection',
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaSectionMixin, tpaResizeWindowMixin],
        getBaseUrl() { // eslint-disable-line complexity
            const appData = this.getAppData();
            const widgetId = this.props.compData.widgetId;
            let sectionUrl = appData.sectionUrl;
            let sectionDefaultPage = appData.sectionDefaultPage;
            const isMobileView = this.isUnderMobileView() && this.isMobileReady();

            if (widgetId) {
                const widget = _.find(appData.widgets, {widgetId});

                if (widget) {
                    sectionDefaultPage = widget.appPage.defaultPage;
                    if (isMobileView) {
                        sectionUrl = this.getTPAOverrideUrl('tpaMobileUrlOverride', widgetId) || widget.mobileUrl;
                    } else {
                        sectionUrl = this.getTPAOverrideUrl('tpaWidgetUrlOverride', widgetId) || widget.widgetUrl;
                    }
                }
            } else if (isMobileView) {
                sectionUrl = appData.sectionMobileUrl;
            }

            if (sectionDefaultPage && !_.isEmpty(sectionDefaultPage)) {
                if (sectionUrl.slice(-1) !== '/') {
                    sectionUrl += '/';
                }

                sectionUrl += sectionDefaultPage;
            }

            return sectionUrl;
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPASection', TPASection);
    return TPASection;
});
