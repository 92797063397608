define(['santa-components'], function (santaComponents) {
    'use strict';

    return {
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },
        getBaseUrl() { // eslint-disable-line complexity
            const appData = this.getAppData();
            if (appData.widgets) {
                const widgetId = this.props.compData.widgetId;
                const widgetData = appData.widgets[widgetId];

                if (widgetData) {
                    const isInDevMode = this.isInMobileDevMode && this.isInMobileDevMode();
                    const widgetUrl = this.getTPAOverrideUrl('tpaWidgetUrlOverride', widgetId) || widgetData.widgetUrl;

                    if (this.isUnderMobileView()) {
                        const mobileReady = widgetData.mobileUrl && (isInDevMode || widgetData.mobilePublished);

                        if (mobileReady) {
                            return this.getTPAOverrideUrl('tpaMobileUrlOverride', widgetId) || widgetData.mobileUrl;
                        }
                        return widgetUrl;
                    }

                    return widgetUrl;
                }
            }
            //TODO - report data error bi
            return '';
        }
    };
});

