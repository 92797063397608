define(['componentsCore'], function (componentsCore) {
    'use strict';

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPAPreloader
     * @extends {tpa.mixins.tpaCompBase}
     */
    const tpaPreloader = {// eslint-disable-line santa/no-module-state
        mixins: [componentsCore.mixins.skinBasedComp],
        getSkinProperties() {
            return {
                preloader: {
                    className: 'circle-preloader'
                }
            };
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAPreloaderOverlay', tpaPreloader);
});
