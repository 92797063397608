/* eslint-disable */
define([
    'lodash',
    'tpaComponents/services/clientSpecMapService',
    'experiment',
    'layout'
], function (
    _,
    clientSpecMapService,
    experiment,
    layout
) {
    'use strict';

    /* eslint-enable */
    function handleMobileFullPage(style, measureMap, siteData) {
        const height = measureMap.height.screen - measureMap.siteOffsetTop;
        style.height = `${height}px`;
        style.minHeight = `${height}px`;
        style.top = `${measureMap.siteOffsetTop}px`;
        if (!siteData.isViewerMode()) {
            style.left = undefined;
            style.right = undefined;
        }

        return style;
    }

    return {
        patchTPASection(id, patchers, measureMap, structureInfo, layoutAPI, hasIframe) { // eslint-disable-line complexity
            let tpaSectionCompHeight = measureMap.height[id];

            const isMobile = layoutAPI.isMobileView();
            const compData = structureInfo.dataItem;
            const clientSpecMap = layoutAPI.getClientSpecMap();
            const appData = clientSpecMap[compData.applicationId];
            const isFullPage = clientSpecMapService.isFullPage(compData, appData);
            const isFullPageInDesktop = clientSpecMapService.isFullPageDesktopOnly(compData, appData) && !isMobile;
            const isAnchors = !layoutAPI.isMesh;
            const shouldSetStyleForFullPageMode = appData && (isFullPage || isFullPageInDesktop) && (isAnchors || isMobile);
            if (shouldSetStyleForFullPageMode) {
                let css = {};
                css.position = 'fixed';
                css.left = '0px';
                css.marginLeft = '0px';
                css.top = '0px';
                css.right = '0px';
                css.marginRight = '0px';
                css.bottom = '0px';

                if (isMobile) {
                    tpaSectionCompHeight = measureMap.height.screen - measureMap.siteOffsetTop;
                    css = handleMobileFullPage(css, measureMap, layoutAPI);
                } else {
                    css.top = layout.spaceCalculationsRegardingWixAds.getFirstUnoccupiedTopCoordinate(measureMap);
                }

                patchers.css(id, css);
                if (hasIframe) {
                    patchers.css(`${id}iframe`, {
                        width: '100%',
                        height: '100%'
                    });
                }
            }

            const tpaSectionCompWidth = measureMap.width[id];
            const isIOS = layoutAPI.os.ios;

            if (hasIframe) {
                if (experiment.isOpen('sv_tpaMobileViewportFix', layoutAPI) && clientSpecMapService.isCurrentPageFullResponsive(layoutAPI)) {
                    patchers.css(`${id}iframe`, {
                        position: 'fixed',
                        height: `calc(100% - ${measureMap.siteOffsetTop}px)`,
                        left: 0
                    });
                } else if (isIOS) { // iOS doesn't send resize event to iframe if its dimensions declared as 100%, so patch them to be in pixels
                    patchers.css(`${id}iframe`, {
                        width: tpaSectionCompWidth,
                        height: tpaSectionCompHeight
                    });
                }
            }
        }
    };
});
