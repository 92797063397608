/* eslint-disable */
define([
    'santa-components',
    'lodash',
    'zepto',
    'prop-types',
    'reactDOM',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/mixins/tpaRuntimeCompMixin',
    'tpaComponents/mixins/tpaResizeWindowMixin',
    'tpaComponents/utils/tpaUtils',
    'skins',
    'tpaComponents/skins/skins.json',
    'componentsCore'
], function(
    santaComponents,
    _,
    $,
    PropTypes,
    reactDOM,
    tpaUrlBuilderMixin,
    tpaCompApiMixin,
    tpaRuntimeCompMixin,
    tpaResizeWindowMixin,
    tpaUtils,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';
    /* eslint-enable */

    const compRegistrar = componentsCore.compRegistrar;
    const MIN_MARGIN = 50;

    /**
     * @class components.TPAModal
     * @extends {ReactCompositeComponent}
     * @extends {tpa.mixins.tpaUrlBuilder}
     * @extends {tpa.mixins.tpaCompAPI}
     * @property {comp.properties} props
     */
    const TPAModal = {
        displayName: 'TPAModal',
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            dynamicClientSpecMapAspect: santaComponents.santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired,
            clientSpecMap: santaComponents.santaTypesDefinitions.RendererModel.clientSpecMap.isRequired,
            windowResizeEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowResizeEvent.isRequired,
            siteScrollingBlocker: santaComponents.santaTypesDefinitions.SiteAspects.siteScrollingBlocker.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            removeModal: santaComponents.santaTypesDefinitions.TPA.removeModal.isRequired,
            exitFullScreenMode: santaComponents.santaTypesDefinitions.exitFullScreenMode.isRequired,
            onCloseCallback: PropTypes.func,
            windowKeyboardEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowKeyboardEvent.isRequired,
            isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode.isRequired,
            ios: santaComponents.santaTypesDefinitions.BrowserFlags.ios.isRequired
        },
        mixins: [
            componentsCore.mixins.skinBasedComp,
            tpaUrlBuilderMixin,
            tpaCompApiMixin,
            tpaRuntimeCompMixin,
            tpaResizeWindowMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],
        getInitialState() {
            this.props.windowResizeEvent.registerToResize(this);

            return {
                showComponent: true,
                registeredEvents: [],
                initialInstance: this.props.dynamicClientSpecMapAspect.getAppInstance(this.props.compData.applicationId) || this.getAppData(this).instance,
                $displayDevice: this.getDeviceType(),
                windowSize: this.props.compData.windowSize
            };
        },

        componentWillMount() {
            if (this.props.windowKeyboardEvent) {
                this.props.windowKeyboardEvent.registerToEscapeKey(this);
            }

            if (this.props.isViewerMode) {
                this.registerOnAnimationEnd(function () {
                    const rootRef = this.refs.dialog;
                    if (rootRef) {
                        reactDOM.findDOMNode(rootRef).focus();
                    }
                    this.unregisterOnAnimationEnd();
                });
            }
        },

        componentDidMount() {
            if (this.props.isViewerMode) {
                const node = reactDOM.findDOMNode(this.refs.dialog);
                if (!node.contains(window.document.activeElement)) {
                    node.focus();
                }
            }

            if (this.getDeviceType() === 'mobile') {
                this.props.siteScrollingBlocker.setSiteScrollingBlockedForModal(this, true, this.props.ios());
            }
        },

        componentWillUnmount() {
            if (this.props.windowKeyboardEvent) {
                this.props.windowKeyboardEvent.unRegisterKeys(this);
            }

            if (this.getDeviceType() === 'mobile') {
                this.props.siteScrollingBlocker.setSiteScrollingBlockedForModal(this, false, this.props.ios());
            }
        },

        componentDidUpdate(prevProps, prevState) {
            const shouldSetHeight =
                this.getDeviceType() === 'mobile' &&
                this.state.height > 0 &&
                prevState.height !== this.state.height;

            if (shouldSetHeight) {
                this.props.siteScrollingBlocker.onHeightChanged(this.props.ios(), this.state.height);
            }
        },

        componentWillReceiveProps(nextProps) {
            const oldInstance = _.get(this.props.clientSpecMap, [this.props.compData.applicationId, 'instance']);
            const newInstance = _.get(nextProps.clientSpecMap, [this.props.compData.applicationId, 'instance']);
            if (oldInstance !== newInstance) {
                this.setState({
                    initialInstance: newInstance
                });
            }
        },

        getDefaultProperties() {
            const iframeSrc = this.buildUrl(this.props.compData.url);
            return {
                '': {
                    style: {position: 'fixed', display: 'block'},
                    onKeyDown: this.preventTabbingOut,
                    'data-src': iframeSrc,
                    tagName: 'wix-iframe'
                },
                blockingLayer: {
                    onClick: this.hide
                },
                frameWrap: {
                    style: this.getIframeWrapperStyle()
                },
                dialog: {
                    style: this.getDialogStyle(),
                    'aria-label': this.props.compData.title || 'Modal Dialog'
                },
                xButton: {
                    children: '×',
                    onClick: this.hide,
                    style: {display: 'block'}
                },
                'iframe': {
                    'data-src': iframeSrc,
                    frameBorder: '0',
                    allow: tpaUtils.getIFramePermissions(),
                    allowtransparency: 'true',
                    allowFullScreen: true,
                    name: this.props.id
                }
            };
        },

        getHiddenProperties() {
            return {
                '': {
                    style: {display: 'none'}
                }
            };
        },

        getBareProperties() {
            return _.merge(this.getDefaultProperties(), {
                frameWrap: {
                    style: {
                        background: 'transparent',
                        border: 'none'
                    }
                },
                xButton: {
                    style: {
                        display: 'none'
                    }
                }
            }, this.props.siteScrollingBlocker.getDeviceSpecificProperties(this.getDeviceType() === 'mobile', this.props.ios(), this.state.height));
        },

        getSkinProperties() {
            if (this.state.showComponent) {
                switch (this.props.compData.theme) {
                    case 'BARE':
                    case 'LIGHT_BOX':
                        return this.getBareProperties();
                    default:
                        return this.getDefaultProperties();
                }
            }

            return this.getHiddenProperties();
        },

        getIframeWrapperStyle() {
            const isMobileView = this.props.isMobileView;
            let style = {};

            if (isMobileView) {
                style = {
                    'WebkitOverflowScrolling': 'touch',
                    'overflowY': 'scroll'
                };
            }

            return style;
        },

        getDialogStyle() {
            if (this.getDeviceType() === 'mobile') {
                return {
                    pointerEvents: 'auto'
                };
            }

            const windowSize = this.state.windowSize;
            let width = _.isNumber(this.state.width) ? this.state.width : this.props.compData.width;
            let height = _.isNumber(this.state.height) ? this.state.height : this.props.compData.height;

            // Normalize to screen measurements
            width = Math.min(width, windowSize.width);
            height = Math.min(height, windowSize.height);

            const appData = this.getAppData();

            if (!appData.isWixTPA) {
                const minWidth = windowSize.width - MIN_MARGIN;
                const minHeight = windowSize.height - MIN_MARGIN;

                if (width >= minWidth && height >= minHeight) {
                    width = minWidth;
                    height = minHeight;
                }
            }


            return {
                width,
                height,
                'marginTop': -height / 2,
                'marginLeft': -width / 2
            };
        },

        mutateIframeUrlQueryParam(queryParamsObj) {
            queryParamsObj.origCompId = this.props.compData.origCompId; // eslint-disable-line santa/no-side-effects
            queryParamsObj.isInModal = true; // eslint-disable-line santa/no-side-effects

            return queryParamsObj;
        },

        hide(data, callback) {
            const self = this;
            this.setState({showComponent: false}, function () {
                const callBackData = data && data.message ? data : undefined;
                if (self.props.onCloseCallback) {
                    self.props.onCloseCallback(callBackData);
                }
                if (self.state.$displayDevice === 'mobile') {
                    self.props.exitFullScreenMode();
                }
                self.props.windowResizeEvent.unregisterToResize(self);

                self.props.removeModal(self);

                if (_.isFunction(callback)) {
                    callback();
                }
            });
        },

        isBareTheme() {
            return this.props.compData.theme === 'BARE';
        },

        onResize() {
            const $window = $(window);
            this.setState({
                windowSize: {
                    width: $window.width(),
                    height: $window.height()
                }
            });
        },

        onEscapeKey() {
            this.hide();
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAModal', TPAModal);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return TPAModal;
});
