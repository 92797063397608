define(['lodash',
    'create-react-class',
    'componentsCore',
    'santa-components'
], function (_,
             createReactClass,
             componentsCore,
             santaComponents) {
    'use strict';

    function getText(text) {
        const app_name = getAppDefinitionName.call(this);
        const textTitleTemplate = _.template(text);
        const __html = ` ${textTitleTemplate({app_name})} `;

        return santaComponents.utils.createReactElement('span', {
            'dangerouslySetInnerHTML': {
                __html
            }
        });
    }

    function getAppDefinitionName() {
        const {props} = this;
        const appData = props.getClientSpecMapEntry(props.compData.applicationId);
        return appData && appData.appDefinitionName;
    }

    const compRegistrar = componentsCore.compRegistrar;

    const TPAWidgetNativeDeadcompName = 'wysiwyg.viewer.components.tpapps.TPAWidgetNativeDeadcomp';

    const {santaTypesDefinitions} = santaComponents;

    /**
     * @class components.TPAUnresponsive
     * @extends {tpa.mixins.tpaCompBase}
     */
    const TPAWidgetNativeDeadcomp = createReactClass({
        displayName: 'TPAWidgetNativeDeadcomp',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            compData: santaTypesDefinitions.Component.compData.isRequired,
            languageCode: santaTypesDefinitions.RendererModel.languageCode.isRequired,
            getClientSpecMapEntry: santaTypesDefinitions.getClientSpecMapEntry.isRequired,
            deadComponentTranslations: santaTypesDefinitions.NativeComponentSantaTypes.deadComponentTranslations
        },

        getSkinProperties() {
            const MIN_WIDTH_TEXT_DISPLAY = 224;
            const CONTENT_PADDING = 17;
            const MAX_TEXT_WIDTH = 206;
            const SVG_WIDTH = 22;
            const {props} = this;
            const {width, height} = props.compData;
            const contentWidth = width < MIN_WIDTH_TEXT_DISPLAY ? SVG_WIDTH : MAX_TEXT_WIDTH;
            const padding = ((width - contentWidth) / 2) - CONTENT_PADDING;
            const textDisplay = width < MIN_WIDTH_TEXT_DISPLAY ? 'none' : 'inherit';
            const {title, text} = props.deadComponentTranslations || {};
            return {
                style: {
                    height
                },
                textTitle: {
                    children: getText.call(this, title),
                    style: {
                        display: textDisplay
                    }
                },
                text: {
                    children: getText.call(this, text),
                    style: {
                        display: textDisplay
                    }
                },
                content: {
                    style: {
                        display: 'table-cell', //block
                        verticalAlign: 'middle',
                        paddingLeft: padding,
                        height,
                        width: contentWidth
                    }
                }
            };
        }
    });

    compRegistrar.register(TPAWidgetNativeDeadcompName, TPAWidgetNativeDeadcomp);

    return TPAWidgetNativeDeadcomp;
});
