/* eslint-disable santa/no-module-state */
define(['layout',
    'tpaComponents/layout/gluedWidgetPatcher',
    'tpaComponents/layout/gluedWidgetMeasurer',
    'tpaComponents/layout/tpaSectionPatcher',
    'tpaComponents/layout/mobileSafariCustomTPAWidgetLayout'
], function (/** layout.layout */ layout,
                                  gluedWidgetPatcher,
                                  gluedWidgetMeasurer,
                                  tpaSectionPatcher,
                                  mobileSafariCustomTPAWidgetLayout) {
    'use strict';

    const TYPE = {
        TPA_GLUED_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
        TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        TPA_WIDGET_NATIVE: 'wysiwyg.viewer.components.tpapps.TPAWidgetNative'
    };

    const registerToMeasureDOMAndIframeChild = type => {
        layout.registerRequestToMeasureDom(type);
        layout.registerRequestToMeasureChildren(type, [['iframe']]);
        layout.registerCustomMeasure(type, customMeasureForAnchors);
    };

    const customMeasureForAnchors = (id, measureMap, nodesMap, structureInfo, layoutAPI) => {
        if (!layoutAPI.isMesh) {
            const minHeight = parseInt(window.getComputedStyle(nodesMap[id]).minHeight || 0, 10);
            const ignoreAnchors = nodesMap[id].dataset.ignoreAnchors === 'true';
            if (minHeight) {
                measureMap.minHeight[id] = ignoreAnchors ? structureInfo.layout.height : minHeight;
                measureMap.height[id] = ignoreAnchors ? structureInfo.layout.height : minHeight;
            }
        }
    };

    const customSectionLayout = (id, nodesMap, measureMap, layoutAPI, structureInfo) => {
        const hasIframe = nodesMap[id].dataset.hasIframe === 'true';
        return patchers => {
            tpaSectionPatcher.patchTPASection(id, patchers, measureMap, structureInfo, layoutAPI, hasIframe);
        };
    };

    const registerInnerAnimationElementsToMeasureMap = (id, measureMap, nodesMap) => {
        if (!nodesMap[id]) {
            return;
        }
        const allAnimationsElements = nodesMap[id].querySelectorAll('*[data-ooi-animate]');
        Array.from(allAnimationsElements).forEach(element => {
            measureMap.height[element.id] = element.offsetHeight;
        });
    };

    [TYPE.TPA_WIDGET, TYPE.TPA_SECTION, TYPE.TPA_MULTI_SECTION].forEach(registerToMeasureDOMAndIframeChild);

    layout.registerRequestToMeasureDom(TYPE.TPA_GLUED_WIDGET);
    layout.registerCustomMeasure(TYPE.TPA_GLUED_WIDGET, gluedWidgetMeasurer);
    layout.registerPatcher(TYPE.TPA_GLUED_WIDGET, gluedWidgetPatcher);

    layout.registerCustomLayoutFunction(TYPE.TPA_MULTI_SECTION, customSectionLayout);
    layout.registerCustomLayoutFunction(TYPE.TPA_SECTION, customSectionLayout);
    layout.registerCustomLayoutFunction(TYPE.TPA_WIDGET, mobileSafariCustomTPAWidgetLayout);
    layout.registerCustomMeasure(TYPE.TPA_WIDGET_NATIVE, registerInnerAnimationElementsToMeasureMap);
});
